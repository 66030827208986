import React from "react";
import { Link } from "react-router-dom";
import { convertDateRange } from "../utils/convertDateRange";

import "./Global.css";

import TagList from "./TagList";
import MemberList from "./MemberList";

import clockIcon from "../images/icon_clock.svg";
import dateIcon from "../images/icon_date.svg";

const VideoCard = ({
  uuid,
  thumbnailUrl,
  title,
  people,
  locations,
  startDate,
  endDate,
  duration,
  tags,
}) => {
  return (
      <div className="c-card">
        <div className="c-card_bg">
          <Link className="c-card_img-container" reloadDocument to={`/video/${uuid}`}>
            <img className ="c-img" src={thumbnailUrl} alt="thumbnail of video" />
            <div className="c-card_overlay">
              <div className="c-card_play-container">
                <h3 className="t-h3 is--tc-white">PLAY VIDEO</h3>
              </div>
            </div>
          </Link>
          <div className="c-card_content-container">
            <Link className="c-card_title-link" reloadDocument to={`/video/${uuid}`}>
                <h2 className="t-h2">{title}</h2>
            </Link>
            <div className="c-card_details">
              <div className="c-card_detail">
                <div className="c-card_icon">
                  <img className ="c-img" src={clockIcon} alt="clock icon" loading="lazy"/>
                </div>
                <div className="c-card_detail-info">
                  <h6 className="t-h6 is--tc-black">{duration}</h6>
                </div>
              </div>
              <div className="c-card_detail is-last">
                <div className="c-card_icon">
                  <img className ="c-img" src={dateIcon} alt="date icon" loading="lazy"/>
                </div>
                <div className="c-card_detail-info">
                  <h6 className="t-h6 is--tc-black">{convertDateRange(startDate, endDate)}</h6>
                </div>
              </div>
            </div>
            <div className="c-card_lists">
              <TagList 
                locations={locations} 
                tags={tags}
                typeClass=''
              />
              <MemberList 
                people={people}
                typeClass=''
              />
            </div>
          </div>
        </div>
        <div id="bg-green" className="c-card_color-bg is--green"></div>
        <div id="bg-yellow" className="c-card_color-bg is--yellow"></div>
        <div id="bg-red" className="c-card_color-bg is--red"></div>
        <div id="bg-cyan" className="c-card_color-bg is--cyan"></div>
      </div>
  );
};

export default VideoCard;


