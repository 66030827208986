import React, { useState, useEffect, useRef, useCallback } from "react";
import UserService from "../../services/user.service";
import { useSearchParams, createSearchParams } from "react-router-dom";
import { logout } from "../../slices/auth";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import SelectedFilters from "../../components/FormComponents/SelectedFilters";
import SearchForm from "./SearchForm";
import SearchResults from "../../components/FormComponents/SearchResults";

export const SearchPage = () => {
  const [form, setForm] = useState({
    tags: [],
    locations: [],
    people: [],
    query: "",
    dateEarliest: "",
    dateLatest: "",
    orderDateAsc: true,
    optionsLoaded: false,
    queryLocked: "", // not for a query in progress, but for one ready to send
    hidden: true,
  });

  const defaultSearchResults = {
    videos: [],
    totalPages: 0,
    nextPage: 1,
    resultsPerPage: 10,
    isLoading: false,
    totalResults: 0,
  };

  const [searchResults, setSearchResults] = useState(defaultSearchResults);
  /*
		Refs requires because the state is referenced in a callback
		when retrieving more search results after scrolling to the bottom
	*/
  const searchResultsRef = useRef();
  searchResultsRef.current = searchResults;

  const formRef = useRef();
  formRef.current = form;

  let [searchParams, setSearchParams] = useSearchParams();

  const searchParamsRef = useRef();
  searchParamsRef.current = searchParams;

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleTextOnChange = (e) => {
    setForm({ ...form, query: e.target.value });
  };

  const handleCheckboxOnChange = (position, key) => {
    const newItems = form[key].map((item, index) => {
      if (index === position) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });
    setForm({ ...form, [key]: newItems });
  };

  const handleRemoveFilter = (uuid, key) => {
    const newFilterState = form[key].map((item) => {
      if (item.uuid === uuid) {
        return { ...item, isChecked: false };
      }
      return item;
    });
    setForm({ ...form, [key]: newFilterState });
  };

  const retrieveSearchResults = (startFresh) => {
    console.log(`retrieveSearchResults(${startFresh})`);
    const params = convertUrlParamsToApiParams(startFresh);

    setSearchResults({ ...searchResultsRef.current, isLoading: true });

    UserService.getSearchResults(params).then(
      (response) => {
        console.log("Received search results from backend");
        setSearchResults({
          ...searchResultsRef.current,
          videos: startFresh
            ? response.data.data.search_results
            : [
                ...searchResultsRef.current.videos,
                ...response.data.data.search_results,
              ],
          totalPages: response.data.data.total_pages,
          nextPage: response.data.data.page + 1,
          isLoading: false,
          totalResults: response.data.data.total_results,
        });
      },
      (error) => {
        const errorMessage = error.response.data;

        if ("logged_in" in errorMessage && !errorMessage.logged_in) {
          console.log("not logged in error");
          logOut();
        }
        console.log(errorMessage.error);
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setForm({ ...form, queryLocked: form.query, hidden: true });
  };

  const handleChangeSortOrder = (e) => {
    e.preventDefault();
    setForm({ ...form, orderDateAsc: !form.orderDateAsc });
  };

  const handleToggleFilterBox = (e) => {
    e.preventDefault();
    setForm({ ...form, hidden: !form.hidden });
  };

  const getCheckedItemsFromUrl = (key) => {
    const urlParam = searchParams.get(key);

    if (urlParam == null) {
      return [];
    }
    const checkedItems = searchParams
      .get(key)
      .split(",")
      .map((itemString) => parseInt(itemString));
    return checkedItems;
  };

  const retrieveFormOptions = () => {
    // retrieve form options, and set the state
    // based on any params in the URL

    const filterStateFromUrl = {
      tags: getCheckedItemsFromUrl("tags"),
      people: getCheckedItemsFromUrl("people"),
      locations: getCheckedItemsFromUrl("locations"),
      query: searchParams.get("q") == null ? "" : searchParams.get("q"),
      orderDateAsc: searchParams.get("asc") == null,
    };

    UserService.getSearchFilters().then(
      (response) => {
        const filterOptions = response.data.filter_options;
        setForm({
          ...form,
          tags: filterOptions.tags.map((item) => {
            return {
              ...item,
              isChecked: filterStateFromUrl.tags.includes(item.uuid),
            };
          }),
          locations: filterOptions.locations.map((item) => {
            return {
              ...item,
              isChecked: filterStateFromUrl.locations.includes(item.uuid),
            };
          }),
          people: filterOptions.people.map((item) => {
            return {
              ...item,
              isChecked: filterStateFromUrl.people.includes(item.uuid),
            };
          }),
          query: filterStateFromUrl.query,
          queryLocked: filterStateFromUrl.query,
          orderDateAsc: filterStateFromUrl.orderDateAsc,
          dateEarliest: filterOptions.date_earliest,
          dateLatest: filterOptions.date_latest,
          optionsLoaded: true,
          hidden: searchParams.toString().length > 0,
        });
      },
      (error) => {
        const errorMessage = error.response.data;

        if ("logged_in" in errorMessage && !errorMessage.logged_in) {
          console.log("not logged in error");
          logOut();
        }
        console.log(errorMessage.error);
      }
    );
  };

  const getNewSearchParams = () => {
    // update the URL accordingly
    const checkedTagIds = getCheckedUuids("tags");
    const checkedPeopleIds = getCheckedUuids("people");
    const checkedLocationIds = getCheckedUuids("locations");

    let newUrlParams = {};
    if (checkedTagIds.length > 0) {
      newUrlParams = { ...newUrlParams, tags: checkedTagIds.join(",") };
    }
    if (checkedPeopleIds.length > 0) {
      newUrlParams = { ...newUrlParams, people: checkedPeopleIds.join(",") };
    }
    if (checkedLocationIds.length > 0) {
      newUrlParams = {
        ...newUrlParams,
        locations: checkedLocationIds.join(","),
      };
    }
    if (!form.orderDateAsc) {
      newUrlParams = { ...newUrlParams, asc: 0 };
    }
    if (form.queryLocked.length > 0) {
      newUrlParams = { ...newUrlParams, q: form.queryLocked };
    }

    return newUrlParams;
  };

  const convertUrlParamsToApiParams = (startFresh) => {
    // these must correspond to the URL params expected by the search endpoint
    const searchParamsOut = {
      tags: searchParamsRef.current.get("tags"),
      locations: searchParamsRef.current.get("locations"),
      people: searchParamsRef.current.get("people"),
      q: searchParamsRef.current.get("q"),
      results_per_page: startFresh
        ? defaultSearchResults.resultsPerPage
        : searchResultsRef.current.resultsPerPage,
      page: startFresh
        ? defaultSearchResults.nextPage
        : searchResultsRef.current.nextPage,
      order_date_asc: searchParamsRef.current.get("asc") == null,
    };

    console.log(
      "Search params from URL: " + searchParamsRef.current.toString()
    );
    console.log(
      "Search params return to the retrieve function: " +
        JSON.stringify(searchParamsOut)
    );

    return searchParamsOut;
  };

  const getCheckedUuids = (key) => {
    return form[key]
      .map((item) => (item.isChecked ? item.uuid : null))
      .filter((itemId) => itemId !== null);
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const distanceFromBottomToTrigger = 50;
      if (
        formRef.current.optionsLoaded &&
        !searchResultsRef.current.isLoading &&
        searchResultsRef.current.nextPage <=
          searchResultsRef.current.totalPages &&
        window.innerHeight + window.scrollY + distanceFromBottomToTrigger >=
          document.body.offsetHeight
      ) {
        console.log("Retrieving results because of scroll trigger");
        retrieveSearchResults(false);
      }
    };

    // On page load, retrieve the form options and set up the scroll listener
    window.addEventListener("scroll", handleScroll);

    // retrieve the form options, but only once (not on each render)
    retrieveFormOptions();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // When a filter is changed, update the URL
    if (form.optionsLoaded) {
      const newUrlParams = getNewSearchParams();
      const newUrlParamsStr = createSearchParams(newUrlParams).toString();
      if (newUrlParamsStr !== searchParams.toString()) {
        /*
          Only update the URL if the query params have changed
          (e.g., this won't be the case if the page loads with params
          in the URL from the beginning)
        */
        setSearchParams(newUrlParams);
      }
    }
    // eslint-disable-next-line
  }, [
    form.tags,
    form.people,
    form.locations,
    form.orderDateAsc,
    form.queryLocked,
  ]);

  useEffect(() => {
    // When the URL query params change, retrieve new search results
    if (form.optionsLoaded) {
      retrieveSearchResults(true);
    }
    // eslint-disable-next-line
  }, [searchParams, form.optionsLoaded]);

  return (
    <Container>
      <Row>
        <Col lg={{ span: 8, offset: 1 }}>
          <SearchForm
            form={form}
            handleSubmit={handleSubmit}
            handleTextOnChange={handleTextOnChange}
            handleCheckboxOnChange={handleCheckboxOnChange}
            handleChangeSortOrder={handleChangeSortOrder}
            handleToggleFilterBox={handleToggleFilterBox}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 8, offset: 1 }}>
          <SelectedFilters
            tags={form.tags}
            people={form.people}
            locations={form.locations}
            handleRemoveFilter={handleRemoveFilter}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={{ span: 8, offset: 1 }}>
          <SearchResults
            totalResults={searchResults.totalResults}
            videos={searchResults.videos}
          />
        </Col>
      </Row>
    </Container>
  );
};
