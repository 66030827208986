import React from "react";
import { Link } from "react-router-dom";
import "./Global.css";

import logo from "../images/logo.png";
import searchIcon from "../images/icon_search.svg";
import loginIcon from "../images/icon_login.svg";
import loginIconBlack from "../images/icon_login_black.svg";
import seatrchIconBlack from "../images/icon_search_black.svg";

const NavigationBar = () => {

  const getNavbarType = () => {
    if(document.location.pathname==='/'){
      return '';
    }else{
      return ' is--nav-opaque';
    };
  };

  return (
    <div>
      {/* Sctoll to Top Button */}
      <div className = "top-btn_container">
        <a href="#navbar" className="c-btn is--top">
          <div className="c-btn_content">
            <p className="t-btn">▲</p>
          </div>
        </a>
      </div>
      {/* Navbar */}
      <div id="navbar" className ={'c-navbar'+ getNavbarType()}>
        <div className ="l-container is--navbar-container">
          <div className ="c-navbar_group">
            <Link className = "c-navbar_link-block"
            to={{
            pathname: "/",
            }}
            reloadDocument
            >
                <div className="c-navbar_logo-container">
                     <img className = "c-img" src={logo} alt="EweTube logo" />
                </div>
                <div className="c-navbar_name-container">
                    <p className={'t-logo'+ getNavbarType()}>EweTube</p>
                </div>
            </Link>
          </div>
          <div className ="c-navbar_group">

          <Link className = "c-navbar_link"
            to={{
            pathname: "/new-video",
            }}
            reloadDocument
            >
              + {/* Replace with an Add Video icon */}
            </Link>

            <Link className = "c-navbar_link"
            to={{
            pathname: "/search",
            }}
            reloadDocument
            >
                <img src={(getNavbarType() === '' ? searchIcon : seatrchIconBlack)} alt="search icon" className="c-img"/>
            </Link>
            <Link className = "c-navbar_link is--last"
            to={{
            pathname: "/profile",
            }}
            reloadDocument
            >
                <img src={(getNavbarType() === '' ? loginIcon : loginIconBlack)} alt="login icon" className="c-img"/>
            </Link>
          </div>
        </div>
      </div>
      </div>
  );
};

export default NavigationBar;