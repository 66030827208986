import React, { useState } from "react";
import { Link } from "react-router-dom";
import FilterCheckbox from "./FilterCheckbox";
import FilterCheckboxTemporary from "./FilterCheckboxTemporary";
import { Form, Button, InputGroup } from "react-bootstrap";
import { MdOutlineCancel, MdOutlineCheckCircle } from "react-icons/md";
import { FiChevronRight, FiChevronUp } from "react-icons/fi";

const FilterGroup = ({
  title,
  subtitle,
  filterKey,
  filters,
  onChange,
  nToDisplay,
  sceneIndex,
  canAddNew,
  handleToggleAddingNewFilter,
  isEnteringNewFilter,
  newFilter,
  handleNewFilterUpdate,
  temporaryFilters,
  handleRemoveNewFilter,
  isSaving,
}) => {
  const [showAll, setShowAll] = useState(false);

  const onToggleShowAll = (e) => {
    e.preventDefault();
    setShowAll(!showAll);
  };

  const handleUpdateNewFilter = (e) => {
    handleNewFilterUpdate(sceneIndex, filterKey, e.target.value);
  };

  const handleClickAddNew = (e) => {
    e.preventDefault();
    handleToggleAddingNewFilter(sceneIndex, filterKey);
  };

  const handleCloseAddNew = (e) => {
    e.preventDefault();
    handleToggleAddingNewFilter(sceneIndex, filterKey);
    // handleNewFilterUpdate(sceneIndex, filterKey, "");
  };

  return (
    <div>
      <div>
        {title} {subtitle ? <>({subtitle})</> : null}
      </div>
      <ul className="filter-list d-flex flex-wrap">
        {filters.map(({ uuid, name }, index) => {
          return (
            <li
              key={index}
              hidden={!showAll && nToDisplay !== null && index > nToDisplay - 1}
            >
              <FilterCheckbox
                name={name}
                uuid={uuid}
                index={index}
                onChange={onChange}
                isChecked={filters[index].isChecked}
                filterKey={filterKey}
                sceneIndex={sceneIndex}
                isSaving={isSaving}
              />
            </li>
          );
        })}

        {temporaryFilters !== undefined
          ? temporaryFilters.map((name, index) => {
              return (
                <li key={index}>
                  <FilterCheckboxTemporary
                    name={name}
                    index={index}
                    handleClick={handleRemoveNewFilter}
                    filterKey={filterKey}
                    sceneIndex={sceneIndex}
                    isSaving={isSaving}
                  />
                </li>
              );
            })
          : null}

        {isEnteringNewFilter ? (
          <li>
            <InputGroup>
              <Form.Control
                value={newFilter}
                onChange={handleUpdateNewFilter}
                autoFocus
                disabled={isSaving}
                aria-describedby={`${filterKey}-${sceneIndex}-addnew`}
              />
              <Button
                variant="success"
                type="submit"
                disabled={isSaving}
                id={`${filterKey}-${sceneIndex}-addnew`}
              >
                <MdOutlineCheckCircle />
              </Button>
              <Button
                variant="danger"
                id={`${filterKey}-${sceneIndex}-addnew`}
                disabled={isSaving}
                onClick={handleCloseAddNew}
              >
                <MdOutlineCancel />
              </Button>
            </InputGroup>
          </li>
        ) : null}

        {canAddNew !== undefined &&
        canAddNew &&
        isEnteringNewFilter !== undefined &&
        !isEnteringNewFilter ? (
          <Link onClick={handleClickAddNew}>+ Add new</Link>
        ) : null}

        {nToDisplay !== null && filters.length > nToDisplay ? (
          <Link onClick={onToggleShowAll}>
            Show{" "}
            {showAll ? (
              <span>
                less
                <FiChevronUp />
              </span>
            ) : (
              <span>
                more
                <FiChevronRight />
              </span>
            )}
          </Link>
        ) : null}
      </ul>
    </div>
  );
};

export default FilterGroup;
