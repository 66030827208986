import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import FilterGroup from "../../components/FormComponents/FilterGroup";
import { GoTriangleUp, GoTriangleDown } from "react-icons/go";
import { MdUnfoldMore, MdUnfoldLess } from "react-icons/md";
import "../../components/FormComponents/filterStyles.css";

const SearchForm = ({
  form,
  handleSubmit,
  handleTextOnChange,
  handleCheckboxOnChange,
  handleChangeSortOrder,
  handleToggleFilterBox,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Row style={{ paddingTop: 2 }}>
        <Col md={9} className="d-flex align-items-center pb-md-0 pb-3">
          <Form.Control
            type="text"
            placeholder="Search"
            value={form.query}
            onChange={handleTextOnChange}
            autoFocus
          />
        </Col>
        <Col
          md={2}
          className="d-flex align-items-center justify-content-center"
        >
          <Button variant="secondary" type="submit">
            Submit
          </Button>
        </Col>
      </Row>
      <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
        <Col
          md={5}
          xs={6}
          className="d-flex align-items-center justify-content-center"
        >
          <Link onClick={handleChangeSortOrder}>
            Sort by Date{" "}
            {form.orderDateAsc ? <GoTriangleUp /> : <GoTriangleDown />}
          </Link>
        </Col>
        <Col
          md={4}
          xs={6}
          className="d-flex align-items-center justify-content-center"
        >
          <Link onClick={handleToggleFilterBox}>
            {form.hidden ? (
              <span>
                <MdUnfoldMore /> Show filters
              </span>
            ) : (
              <span>
                <MdUnfoldLess /> Hide filters
              </span>
            )}
          </Link>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col
            className={`filter-container ${
              form.hidden ? "hidden-filter-container" : null
            }`}
          >
            <FilterGroup
              title="Tags"
              subtitle="match all of these"
              filterKey="tags"
              filters={form.tags}
              onChange={handleCheckboxOnChange}
            />

            <FilterGroup
              title="People"
              subtitle="match all of these"
              filterKey="people"
              filters={form.people}
              onChange={handleCheckboxOnChange}
            />

            <FilterGroup
              title="Locations"
              subtitle="match any of these"
              filterKey="locations"
              filters={form.locations}
              onChange={handleCheckboxOnChange}
              nToDisplay={15}
            />
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default SearchForm;
