import React from "react";
import FilterCheckboxSelected from "./FilterCheckboxSelected";

const SelectedFilters = ({ tags, people, locations, handleRemoveFilter }) => {

  return (
    <div className="selected-filters-section">
      {tags
        .filter((item) => item.isChecked)
        .map(({ uuid, name }, index) => {
          return (
            <FilterCheckboxSelected
              index={index}
              uuid={uuid}
              name={name}
              onClick={handleRemoveFilter}
              filterKey={"tags"}
              key={index}
            />
          );
        })}

      {people
        .filter((item) => item.isChecked)
        .map(({ uuid, name }, index) => {
          return (
            <FilterCheckboxSelected
              index={index}
              uuid={uuid}
              name={name}
              onClick={handleRemoveFilter}
              filterKey={"people"}
              key={index}
            />
          );
        })}

      {locations
        .filter((item) => item.isChecked)
        .map(({ uuid, name }, index) => {
          return (
            <FilterCheckboxSelected
              index={index}
              uuid={uuid}
              name={name}
              onClick={handleRemoveFilter}
              filterKey={"locations"}
              key={index}
            />
          );
        })}
    </div>
  );
};

export default SelectedFilters;
