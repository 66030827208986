import React, { useState, useEffect, useRef, useCallback } from "react";
import { logout } from "../../slices/auth";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import VideoPageContent from "./VideoPageContent";

export const VideoPage = () => {
  const maxVideoHeight = 500;
  const defaultNavbarHeight = 56;
  const navbarHeight = document.getElementById("nav-bar")
    ? document.getElementById("nav-bar").clientHeight
    : defaultNavbarHeight;

  const calcVideoHeight = () => {
    return Math.min(
      maxVideoHeight,
      window.innerHeight - navbarHeight,
      window.innerWidth * 0.9
    );
  };

  const [content, setContent] = useState("");
  const [videoQueue, setVideoQueue] = useState([]);
  const [nextPage, setNextPage] = useState(1);
  const [loadingMoreQueue, setLoadingMoreQueue] = useState(false);
  const [videoHeight, setVideoHeight] = useState(calcVideoHeight());
  const [reload, setReload] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const contentRef = useRef();
  contentRef.current = content;

  const nextPageRef = useRef();
  nextPageRef.current = nextPage;

  const videoQueueRef = useRef();
  videoQueueRef.current = videoQueue;

  const loadingRef = useRef();
  loadingRef.current = loadingMoreQueue;

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const triggerReload = () => {
    setReload(!reload);
  };

  // video ID from the URL
  const { id } = useParams();

  const retrieveQueue = (videoId) => {
    setLoadingMoreQueue(true);
    return UserService.getVideoQueue(videoId, nextPageRef.current)
      .then(
        (queueResponse) => {
          setVideoQueue([
            ...videoQueueRef.current,
            ...queueResponse.data.data.video_queue,
          ]);
          setNextPage(nextPageRef.current + 1);
        },
        (error) => {
          const errorMessage = error.response.data;

          if ("logged_in" in errorMessage && !errorMessage.logged_in) {
            console.log("not logged in error");
            logOut();
          }
          console.log(errorMessage.error);
        }
      )
      .then(() => {
        setLoadingMoreQueue(false);
      });
  };

  const handleScroll = (e) => {
    const offsetFromBottom = 100;
    if (
      !loadingRef.current &&
      window.innerHeight + window.scrollY + offsetFromBottom >=
        document.body.offsetHeight &&
      contentRef.current
    ) {
      console.log("Requesting more videos");
      retrieveQueue(contentRef.current.video_data.uuid);
    }
  };

  useEffect(() => {
    const handleHeightResize = (e) => {
      setVideoHeight(calcVideoHeight());
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleHeightResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleHeightResize);
    };
  });

  useEffect(() => {
    setIsEditing(false);
    UserService.getVideoContent(id)
      .then(
        (response) => {
          setContent(response.data.data);
          return response.data.data;
        },
        (error) => {
          const errorMessage = error.response.data;

          if ("logged_in" in errorMessage && !errorMessage.logged_in) {
            console.log("not logged in error");
            logOut();
          }
          console.log(errorMessage.error);
        }
      )
      .then((data) => {
        retrieveQueue(data.video_data.uuid);
      })
      .catch((e) => {
        console.log("Error");
      });
    // eslint-disable-next-line
  }, [id, logOut, reload]);

  return (
    <>
      <VideoPageContent
        videoData={content.video_data}
        aggData={content.agg_data}
        filterOptions={content.filter_options}
        signedCdnUrl={content.signed_cdn_url}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        videoHeight={videoHeight}
        triggerReload={triggerReload}
        loadingMoreQueue={loadingMoreQueue}
        videoQueue={videoQueue}
      />
    </>
  );
};
