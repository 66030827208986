import React from "react";
import CollectionLink from "../../components/ContentLinks/CollectionLink";
import "./home.css";

const FeaturedCollections = ({ data }) => {
  const renderCollections = (collections) => {
    return collections.map((collection, index) => (
      <CollectionLink
        uuid={collection.filter_uuid}
        name={collection.filter_name}
        type={collection.filter_type}
        thumbnailUrl={collection.thumbnail_url}
        key={index}
      />
    ));
  };

  return (
    <div>
      <div className="c-section_wrapper">
        <div className="c-section_title">
          <h3 className="t-h3 is--tc-black">Browse videos by category</h3>
        </div>
        <div className="c-categories_grid">
          {renderCollections(data)}
        </div>
      </div>
    </div>
  );
};

export default FeaturedCollections;