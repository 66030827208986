export const convertDateRange = (startDateString, endDateString) => {
  if (startDateString === null || startDateString.length === 0) {
    return;
  }

  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  // for formatting
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "UTC",
  };

  const locale = "en-US";

  const startDateStringFmt = startDate.toLocaleDateString(locale, options);
  const endDateStringFmt = endDate.toLocaleDateString(locale, options);

  if (startDateString === endDateString) {
    return startDateStringFmt;
  } else if (
    startDate.getUTCMonth() === endDate.getUTCMonth() &&
    startDate.getUTCFullYear() === endDate.getUTCFullYear()
  ) {
    return (
      `${startDate.toLocaleDateString(locale, { month: "short", timeZone: "UTC" })} ` +
      `${startDate.toLocaleDateString(locale, { day: "numeric", timeZone: "UTC" })}` +
      "-" +
      `${endDate.toLocaleDateString(locale, { day: "numeric", timeZone: "UTC" })}` +
      `, ${endDate.toLocaleDateString(locale, { year: "numeric", timeZone: "UTC" })}`
    );
  }

  return `${startDateStringFmt} – ${endDateStringFmt}`;
};
