import React from "react";
import "./filterStyles.css";
import { RxCross2 } from "react-icons/rx";

const FilterCheckboxTemporary = ({
  index,
  name,
  handleClick,
  filterKey,
  sceneIndex,
  isSaving,
}) => {
  const maxNameLength = 30;

  const truncateName = (name) => {
    if (name.length > maxNameLength) {
      return `${name.substring(0, maxNameLength)}…`;
    }
    return name;
  };

  const handleClickInternal = (e) => {
    if (isSaving === undefined || !isSaving) {
      handleClick(e, filterKey, sceneIndex, index)
    }
  }

  const filterId = `temporary-filter-${filterKey}-${index}-${sceneIndex}`;

  return (
    <div
      className="filter-outer filter-checked"
      onClick={handleClickInternal}
    >
      <div className="filter-inner">
        <input type="checkbox" name={name} id={filterId} disabled={true} />
        <label htmlFor={filterId} className="filter-label">
          <RxCross2 /> {truncateName(name)}
        </label>
      </div>
    </div>
  );
};

export default FilterCheckboxTemporary;
