import React from "react";
import "./Global.css";

import Pill from "./Pill";

const TagList = ({tags, locations, typeClass}) =>{
  
  const renderLocations = (locations) => {
    return locations.map((location, index) => (
            <Pill key={index}
                uuid={location.uuid}
                name={location.location}
                typeClass = {typeClass}
                type = 'locations'
            />
    ));
  };

  const renderTags = (tags) => {
    return tags.map((tag, index) => (
            <Pill key={index}
                uuid={tag.uuid}
                name={tag.tag}
                typeClass = {typeClass}
                type = 'tags'
            />
    ));
  };

  return (
    <div className="c-tag-list">
      { locations ?
        renderLocations(locations)
        : null
      }
      { tags ?
        renderTags(tags)
        : null
      }
    </div>
  );
};

export default TagList;