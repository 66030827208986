import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import EditSceneForm from "./EditSceneForm";
import {
  MdOutlineCancel,
  MdOutlineCheckCircle,
  MdLockOutline,
  MdLockOpen,
} from "react-icons/md";

const EditVideoForm = ({
  handleSubmit,
  video,
  scenes,
  handleVideoFormChange,
  handleCheckboxChange,
  handleCancelEdit,
  isSaving,
  handleToggleAddingNewFilter,
  handleNewFilterUpdate,
  handleRemoveNewFilter,
  handleAddScene,
  handleDeleteScene,
  validated,
  handleUpdatePhysMediaType,
  adminUnlocked,
  handleAdminToggleLock,
  handleSceneFormChange,
  videoIsNew,
  handleDeleteVideo,
  showDeleteModal,
  handleToggleDeleteModal,
}) => {
  const renderFormComponents = () => {
    if (scenes.length >= 1) {
      return (
        <div>
          <Form.Group>
            <Form.Label htmlFor="videoTitle">
              <h5>Video Title:</h5>
            </Form.Label>
            <Form.Control
              value={scenes.length > 1 ? video.title : scenes[0].description}
              onChange={(e) => handleVideoFormChange(e, "title")}
              id="videoTitle"
              size="lg"
              disabled={isSaving || scenes.length === 1}
              required={scenes.length > 1}
            />
            <Form.Control.Feedback type="invalid">
              Required
            </Form.Control.Feedback>
            {scenes.length === 1 ? (
              <span>
                (if there is only 1 scene, the title is borrowed from the
                scene's description)
              </span>
            ) : null}
          </Form.Group>

          <h5 style={{ marginTop: 20 }}>Edit Scenes:</h5>

          {/* Add a new scene before the first scene */}
          <div className="text-center">
            <Button
              variant="light"
              onClick={(e) => handleAddScene(e, -1)}
              disabled={isSaving}
            >
              + New Scene
            </Button>
          </div>

          {scenes.map((scene, index) => {
            return (
              <div key={index}>
                <h6 style={{ paddingBottom: 2, marginTop: 20 }}>
                  Scene {index + 1}: {scene.isNew ? "(New)" : ""}
                </h6>
                <EditSceneForm
                  scene={scene}
                  index={index}
                  handleCheckboxChange={handleCheckboxChange}
                  isSaving={isSaving}
                  handleToggleAddingNewFilter={handleToggleAddingNewFilter}
                  handleNewFilterUpdate={handleNewFilterUpdate}
                  handleRemoveNewFilter={handleRemoveNewFilter}
                  handleDeleteScene={handleDeleteScene}
                  totalScenes={scenes.length}
                  handleUpdatePhysMediaType={handleUpdatePhysMediaType}
                  handleSceneFormChange={handleSceneFormChange}
                />

                {/* Add a new scene after this scene */}
                <div className="text-center">
                  <Button
                    variant="light"
                    onClick={(e) => handleAddScene(e, index)}
                    disabled={isSaving}
                  >
                    + New Scene
                  </Button>
                </div>
              </div>
            );
          })}

          {/* This is a separate admin area -- should restrict to only certain accounts */}

          <Form.Group>
            <Form.Label>
              <h5>
                Video Hosting Details{" "}
                {!videoIsNew ? (
                  <Link onClick={handleAdminToggleLock}>
                    {adminUnlocked ? <MdLockOpen /> : <MdLockOutline />}
                  </Link>
                ) : null}
              </h5>
            </Form.Label>
            <Form.Group>
              <Form.Label>Hosed Video ID</Form.Label>
              <Form.Control
                value={video.hostedVideoId}
                disabled={!adminUnlocked || isSaving}
                id="edit-video-id"
                onChange={(e) => handleVideoFormChange(e, "hostedVideoId")}
                required
              />
              <Form.Control.Feedback type="invalid">
                Required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Thumbnail URL</Form.Label>
              <Form.Control
                value={video.thumbnailUrl}
                disabled={!adminUnlocked || isSaving}
                id="edit-video-thumbnail"
                onChange={(e) => handleVideoFormChange(e, "thumbnailUrl")}
                required
              />
              <Form.Control.Feedback type="invalid">
                Required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Video Preview URL</Form.Label>
              <Form.Control
                value={video.previewUrl}
                disabled={!adminUnlocked || isSaving}
                id="edit-video-preview"
                onChange={(e) => handleVideoFormChange(e, "previewUrl")}
                required
              />
              <Form.Control.Feedback type="invalid">
                Required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Video Duration:</Form.Label>
              <Form.Control
                required
                pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]"
                value={video.duration}
                onChange={(e) => handleVideoFormChange(e, "duration")}
                disabled={!adminUnlocked || isSaving}
                id="edit-video-duration"
              />
              <Form.Control.Feedback type="invalid">
                Must be a valid timestamp HH:MM:SS.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Group>
          
          <div hidden={videoIsNew} style={{ paddingTop: 20, textAlign: "center"}}>
          <Button
              variant={adminUnlocked ? "danger" : "outline-danger"}
              onClick={handleToggleDeleteModal}
              disabled={!adminUnlocked || isSaving}
            >
              Delete Video
            </Button>
            </div>

            {/* Begin modal to confirm video delete */}
            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showDeleteModal}
              onHide={handleToggleDeleteModal}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Are you sure you want to delete this video?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  This would be hard to undo.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleToggleDeleteModal}>Cancel</Button>
                <Button variant="danger" onClick={handleDeleteVideo}>Delete the video</Button>
              </Modal.Footer>
            </Modal>
            {/* End modal to confirm video delete */}

          {/* End admin area */}
        </div>
      );
    }
  };

  const renderForm = () => {
    if (scenes) {
      return (
        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <Form.Group className="text-right">
            <Button
              variant="danger"
              onClick={handleCancelEdit}
              disabled={isSaving}
            >
              <MdOutlineCancel /> Cancel
            </Button>
            <Button variant="success" type="submit" disabled={isSaving}>
              <MdOutlineCheckCircle /> Save
            </Button>
          </Form.Group>
          {renderFormComponents()}
        </Form>
      );
    }
  };

  return <>{renderForm()}</>;
};

export default EditVideoForm;
