import React from "react";
import "./Global.css";

import MemberLinkLarge from "./MemberLinkLarge";

const MemberListLarge = ({people}) =>{
  
  const renderMembers = (people) => {
    return people.map((person, index) => (
            <MemberLinkLarge key={index}
                uuid={person.uuid}
                name={person.person}
                image = {person.image}
            />
    ));
  };

  return (
    <div className="c-members_grid">
      { people ?
        renderMembers(people)
        : null
      }
    </div>
  );
};

export default MemberListLarge;