import React from "react";

const PhysicalMediaLink = ({ mediaType, mediaNumber }) => {
  return (
    <>
      {mediaNumber == null ? (
        mediaType
      ) : (
        <span>
          {mediaType} Tape #{mediaNumber}
        </span>
      )}
    </>
  );
};

export default PhysicalMediaLink;
