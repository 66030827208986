import axios from "axios";
import apiUrl from "./api.js";

const API_URL = apiUrl() + "auth/";

const login = (credential) => {
    return axios
      .post(API_URL + "login", {
        credential,
      })
      .then((response) => {
        if (response.data && response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        else if (response.data && response.data.error) {
            console.log(response.data)
        }
        else if (!response.data.logged_in) {
            logout();
        }

        return response.data;
      })
      .catch(err => {
        console.log("login request error")
        console.log(err.toJSON());
      });
  };
  
  const logout = () => {
    console.log("Logging out.")
    localStorage.removeItem("user");
  };
  
  const authService = {
    login,
    logout,
  };
  
  export default authService;