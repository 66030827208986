import React from "react";
import { convertDateRange } from "../../utils/convertDateRange";
import "./video.css";

import MemberList from "../../components/MemberList";
import dateIcon from "../../images/icon_date.svg";
import TagList from "../../components/TagList";

const SceneCard = ({
  description,
  people,
  locations,
  tags,
  startDate,
  endDate,
  index,
  startTimeOnVideo,
}) => {
  return (
    <div className="c-scene-card">
      <div className="c-card_title-link">
        <h5 className="t-h2">
          Scene {index + 1}{startTimeOnVideo == null ? '' : ` (${startTimeOnVideo})`}: {description}
        </h5>
      </div>
      <div className="c-card_details">
        <div className="c-card_detail is-last">
          <div className="c-card_icon">
            <img className ="c-img" src={dateIcon} alt="date icon" loading="lazy"/>
          </div>
          <div className="c-card_detail-info">
            <h6 className="t-h6 is--tc-black">{convertDateRange(startDate, endDate)}</h6>
          </div>
        </div>
      </div>
      <div className="c-card_lists">
        <MemberList 
          people={people}
          typeClass=''
        />
        <TagList 
          locations={locations} 
          tags ={tags}
          typeClass=''
        />
      </div>
    </div>
  );
};

export default SceneCard;
