import React from "react";
import "./filterStyles.css";

const FilterCheckbox = ({
  uuid,
  name,
  isChecked,
  onChange,
  index,
  filterKey,
  sceneIndex /* in case there are multiple scenes with filters */,
  isSaving,
}) => {
  const maxNameLength = 30;
  const filterId = `${filterKey}-checkbox-${index}-scene-${sceneIndex ? sceneIndex : 0}`;

  const truncateName = (name) => {
    if (name.length > maxNameLength) {
      return `${name.substring(0, maxNameLength)}…`;
    }
    return name;
  };

  return (
    <div className={`filter-outer ${isChecked ? "filter-checked" : null}`}>
      <div className="filter-inner">
        <input
          type="checkbox"
          name={name}
          value={uuid}
          id={filterId}
          checked={isChecked}
          disabled={isSaving !== null && isSaving}
          onChange={() => onChange(index, filterKey, sceneIndex)}
        />
        <label htmlFor={filterId} className="filter-label">
          {truncateName(name)}
        </label>
      </div>
    </div>
  );
};

export default FilterCheckbox;
