import React, { useState, useEffect } from "react";
import EditVideo from "./EditVideo";
import VideoDetailsContent from "./VideoDetailsContent";

const VideoDetails = ({
  title,
  startDate,
  endDate,
  locations,
  tags,
  people,
  scenes,
  video,
  filterOptions,
  isEditing,
  setIsEditing,
  triggerReload,
  comments,
}) => {
  const sceneHeightClosed = "40px";

  const [sceneHeight, setSceneHeight] = useState(sceneHeightClosed);
  const [scenesOpen, setScenesOpen] = useState(false);

  const toggleScenesOpen = (e) => {
    e.preventDefault();
    setScenesOpen(!scenesOpen);
  };

  const handleOpenEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  useEffect(() => {
    setSceneHeight(scenesOpen ? "auto" : sceneHeightClosed);
  }, [scenesOpen]);

  return (
    <>
      {isEditing ? (
        <EditVideo
          video={video}
          filterOptions={filterOptions}
          handleCancelEdit={handleCancelEdit}
          triggerReload={triggerReload}
        />
      ) : (
        <VideoDetailsContent
          title={title}
          toggleScenesOpen={toggleScenesOpen}
          handleOpenEdit={handleOpenEdit}
          startDate={startDate}
          endDate={endDate}
          locations={locations}
          tags={tags}
          people={people}
          scenes={scenes}
          sceneHeight={sceneHeight}
          scenesOpen={scenesOpen}
          comments={comments}
          videoUuid={video.uuid}
        />
      )}
    </>
  );
};

export default VideoDetails;
