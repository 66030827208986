import React, { useState, useEffect, useCallback, useRef } from "react";
import { logout } from "../../slices/auth";
import { useDispatch } from "react-redux";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import {
  MdOutlineEdit,
  MdOutlineCancel,
  MdOutlineCheckCircle,
} from "react-icons/md";
import UserService from "../../services/user.service";
import "./comments.css";

const Comments = ({ comments, videoUuid }) => {
  const [commentInEdit, setCommentInEdit] = useState(null);
  const [commentsState, setCommentsState] = useState([]);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [newComment, setNewComment] = useState({ isEditing: false, text: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const newCommentInput = useRef(null);

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const renderDate = (dateStr) => {
    const date = new Date(dateStr);

    // for formatting
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const locale = "en-US";

    const dateStrFormat = date.toLocaleTimeString(locale, options);

    return dateStrFormat;
  };

  const handleClickEdit = (e, commentId, commentText) => {
    e.preventDefault();
    setCommentInEdit({
      id: commentId,
      text: commentText,
    });
  };

  const handleEditCommentForm = (e) => {
    setCommentInEdit({ ...commentInEdit, text: e.target.value });
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setCommentInEdit(null);
  };

  const handleRegisterDelete = () => {
    UserService.deleteComment(commentToDelete).then(
      (response) => {
        console.log(`Deleted comment ${commentToDelete}`);
        const deletedComment = commentToDelete;
        setCommentToDelete(null);
        setCommentsState(
          commentsState.filter((item) => {
            return item.uuid !== deletedComment;
          })
        );
      },
      (error) => {
        const errorMessage = error.response.data;

        if ("logged_in" in errorMessage && !errorMessage.logged_in) {
          console.log("not logged in error");
          logOut();
        }
        console.log(errorMessage.error);
      }
    );
  };

  const handleSubmitEditComment = (e) => {
    setIsSubmitting(true);
    e.preventDefault();

    const requestData = {
      comment: commentInEdit.text,
    };

    UserService.putUpdateComment(requestData, commentInEdit.id)
      .then(
        (response) => {
          const commentIdResponse = parseInt(response.data.commentUuid);
          setCommentsState(
            commentsState.map((item) => {
              if (item.uuid === commentIdResponse) {
                return { ...item, comment: response.data.comment };
              } else {
                return item;
              }
            })
          );

          setCommentInEdit(null);
        },
        (error) => {
          const errorMessage = error.response.data;

          if ("logged_in" in errorMessage && !errorMessage.logged_in) {
            console.log("not logged in error");
            logOut();
          }
          console.log(errorMessage.error);
        }
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClickDelete = (e, uuid) => {
    e.preventDefault();
    setCommentToDelete(uuid);
  };

  const handleCloseModal = () => {
    setCommentToDelete(null);
  };

  const handleBeginEditNewComment = () => {
    setNewComment({ ...newComment, isEditing: true });
  };

  const handleCancelNewComment = () => {
    setNewComment({ isEditing: false, text: "" });
  };

  const handleEditNewComment = (e) => {
    setNewComment({ ...newComment, text: e.target.value });
  };

  const handleSubmitNewComment = (e) => {
    e.preventDefault();
    newCommentInput.current.blur();
    if (newComment.text.length === 0) {
      console.log("Cannot submit empty comment");
      setNewComment({ isEditing: false, text: "" });
      return;
    }

    setIsSubmitting(true);

    // as expected by the API
    const data = {
      id: videoUuid,
      comment: newComment.text,
    };

    UserService.createComment(data)
      .then(
        (response) => {
          console.log(`Created comment ${response.data.uuid}`);

          setCommentsState([...commentsState, response.data]);
          setNewComment({ isEditing: false, text: "" });
        },
        (error) => {
          const errorMessage = error.response.data;

          if ("logged_in" in errorMessage && !errorMessage.logged_in) {
            console.log("not logged in error");
            logOut();
          }
          console.log(errorMessage.error);
        }
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (comments) {
      setCommentsState(comments);
    } else {
      setCommentsState([]);
    }
  }, [comments]);

  const renderComment = (comment, index) => {
    return (
      <li key={index}>
        <div>
          <span style={{ fontWeight: "bold" }}>
            {comment.user_display_name}
          </span>{" "}
          <span style={{ fontSize: "0.8em" }}>
            {renderDate(comment.created_at)}
          </span>
          {comment.is_editable ? (
            <div style={{ display: "inline", marginLeft: 5 }}>
              <ButtonGroup>
                <Button
                  onClick={(e) =>
                    handleClickEdit(e, comment.uuid, comment.comment)
                  }
                  variant="secondary"
                  size="sm"
                  disabled={
                    commentInEdit !== null ||
                    isSubmitting ||
                    newComment.isEditing
                  }
                >
                  <MdOutlineEdit />
                  Edit
                </Button>
                <Button
                  onClick={(e) => handleClickDelete(e, comment.uuid)}
                  variant="secondary"
                  size="sm"
                  disabled={
                    commentInEdit !== null ||
                    isSubmitting ||
                    newComment.isEditing
                  }
                >
                  <MdOutlineCancel />
                  Delete
                </Button>
              </ButtonGroup>
            </div>
          ) : null}
        </div>
        <div>
          {commentInEdit !== null && commentInEdit.id === comment.uuid ? (
            <div>
              <Form onSubmit={handleSubmitEditComment}>
                <Form.Control
                  value={commentInEdit.text}
                  onChange={handleEditCommentForm}
                />
                <ButtonGroup>
                  <Button
                    variant="success"
                    size="sm"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <MdOutlineCheckCircle /> Save
                  </Button>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={handleCancelEdit}
                    disabled={isSubmitting}
                  >
                    <MdOutlineCancel /> Cancel
                  </Button>
                </ButtonGroup>
              </Form>
            </div>
          ) : (
            <div>{comment.comment}</div>
          )}
        </div>
      </li>
    );
  };

  return (
    <>
      <h4>
        Footnotes{" "}
        {commentsState && commentsState.length > 0 ? (
          <>({commentsState.length})</>
        ) : null}
      </h4>
      <div>
        <Form onSubmit={handleSubmitNewComment}>
          <Form.Control
            placeholder="Add a footnote..."
            value={newComment.text}
            onChange={handleEditNewComment}
            onFocus={handleBeginEditNewComment}
            disabled={commentInEdit !== null}
            ref={newCommentInput}
          ></Form.Control>
          <ButtonGroup hidden={!newComment.isEditing}>
            <Button onClick={handleCancelNewComment} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit">
              Submit
            </Button>
          </ButtonGroup>
        </Form>
      </div>
      <ul className="comment-list">
        {commentsState
          ? commentsState.map((comment, index) => {
              return renderComment(comment, index);
            })
          : null}
      </ul>

      <Modal show={commentToDelete !== null} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Permanently delete this footnote?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRegisterDelete}>
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Comments;
