import React from "react";
import "./Global.css";

const SectionBorder = () =>{
  return (
    <div className="c-section-border">
      <div className="l-container">
        <div className="c-section-border_border"></div>
      </div>
    </div>
  );
};

export default SectionBorder;