import React, { useState, useEffect, useCallback } from "react";
import { logout } from "../../slices/auth";
import { useDispatch } from "react-redux";
import UserService from "../../services/user.service";
import HomePageContent from "./HomePageContent";

export const HomePage = () => {
  const [content, setContent] = useState("");

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    UserService.getHomePageContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const errorMessage = error.response.data;

        if ("logged_in" in errorMessage && !errorMessage.logged_in) {
          console.log("not logged in error");
          logOut();
        }
      }
    );
  }, [logOut]);

  return (
    <>
      <HomePageContent data={content.data} />
    </>
  );
};
