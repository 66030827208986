import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import FilterGroup from "../../components/FormComponents/FilterGroup";
import { TfiLocationPin } from "react-icons/tfi";
import { BsPerson } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";

const EditSceneForm = ({
  scene,
  index,
  handleCheckboxChange,
  isSaving,
  handleToggleAddingNewFilter,
  handleNewFilterUpdate,
  handleRemoveNewFilter,
  handleDeleteScene,
  totalScenes,
  handleUpdatePhysMediaType,
  handleSceneFormChange,
}) => {
  return (
    <Form.Group
      style={{
        border: "1px solid darkgray",
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 5,
        paddingRight: 10,
        borderRadius: "10px",
      }}
    >
      <div className="text-right">
        <Button
          variant="danger"
          onClick={(e) => handleDeleteScene(e, index)}
          disabled={isSaving || totalScenes < 2}
        >
          <MdOutlineCancel /> delete scene{" "}
          {totalScenes < 2 ? (
            <span>
              <br />
              (can't delete only remaining scene)
            </span>
          ) : (
            ""
          )}
        </Button>
      </div>
      <Form.Group>
        <Form.Label>Scene description:</Form.Label>
        <Form.Control
          value={scene.description}
          id={`scene-${index}-description`}
          onChange={(e) => handleSceneFormChange(e, index, "description")}
          disabled={isSaving}
          required
        />
        <Form.Control.Feedback type="invalid">
          Scene description is required.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Timestamp when scene starts:</Form.Label>
        <Form.Control
          required
          pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]"
          value={scene.startTimeOnVideo == null ? "" : scene.startTimeOnVideo}
          id={`scene-${index}-start-time`}
          onChange={(e) => handleSceneFormChange(e, index, "startTimeOnVideo")}
          disabled={isSaving}
        />
        <Form.Control.Feedback type="invalid">
          Must be a valid timestamp HH:MM:SS.
        </Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Start Date:</Form.Label>
          <Form.Control
            value={scene.startDate}
            id={`scene-${index}-start-date`}
            onChange={(e) => handleSceneFormChange(e, index, "startDate")}
            disabled={isSaving}
            type="date"
            required
          />
          <Form.Control.Feedback type="invalid">
            Start date is required.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>End Date:</Form.Label>
          <Form.Control
            value={scene.endDate}
            id={`scene-${index}-end-date`}
            onChange={(e) => handleSceneFormChange(e, index, "endDate")}
            disabled={isSaving}
            type="date"
            required
          />
          <Form.Control.Feedback type="invalid">
            End date is required.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Form.Group>
        <FilterGroup
          title="#Tags"
          filterKey="tags"
          filters={scene.tags}
          onChange={handleCheckboxChange}
          sceneIndex={index}
          nToDisplay={20}
          canAddNew={true}
          handleToggleAddingNewFilter={handleToggleAddingNewFilter}
          isEnteringNewFilter={scene.enteringNewFilter["tags"]}
          handleNewFilterUpdate={handleNewFilterUpdate}
          newFilter={scene.newFilterBeingEdited["tags"]}
          temporaryFilters={scene.committedNewFilter["tags"]}
          handleRemoveNewFilter={handleRemoveNewFilter}
          isSaving={isSaving}
        />
      </Form.Group>
      <Form.Group>
        <FilterGroup
          title={
            <>
              <BsPerson /> People
            </>
          }
          filterKey="people"
          filters={scene.people}
          onChange={handleCheckboxChange}
          sceneIndex={index}
          nToDisplay={20}
          canAddNew={true}
          handleToggleAddingNewFilter={handleToggleAddingNewFilter}
          isEnteringNewFilter={scene.enteringNewFilter["people"]}
          handleNewFilterUpdate={handleNewFilterUpdate}
          newFilter={scene.newFilterBeingEdited["people"]}
          temporaryFilters={scene.committedNewFilter["people"]}
          handleRemoveNewFilter={handleRemoveNewFilter}
          isSaving={isSaving}
        />
      </Form.Group>
      <Form.Group>
        <FilterGroup
          title={
            <>
              <TfiLocationPin /> Locations{" "}
            </>
          }
          filterKey="locations"
          filters={scene.locations}
          onChange={handleCheckboxChange}
          sceneIndex={index}
          nToDisplay={20}
          canAddNew={true}
          handleToggleAddingNewFilter={handleToggleAddingNewFilter}
          isEnteringNewFilter={scene.enteringNewFilter["locations"]}
          handleNewFilterUpdate={handleNewFilterUpdate}
          newFilter={scene.newFilterBeingEdited["locations"]}
          temporaryFilters={scene.committedNewFilter["locations"]}
          handleRemoveNewFilter={handleRemoveNewFilter}
          isSaving={isSaving}
        />
      </Form.Group>

      {/* factor this out to a separate component later, which is only displayed if requested */}
      <Form.Group>
        <h4>Archival Details</h4>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Physical Media Type:</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => handleUpdatePhysMediaType(e, index)}
              value={
                scene.physicalMediaType == null ? "" : scene.physicalMediaType
              }
              id={`scene-${index}-phys-media-type`}
              required
            >
              <option value="">Select a physical media type</option>
              <option value="VHS-C">VHS-C</option>
              <option value="MiniDV">MiniDV</option>
              <option value="VHS">VHS</option>
              <option value="Edited">Edited</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Tape Number:</Form.Label>
            <Form.Control
              value={
                scene.physicalMediaTapeNum == null
                  ? ""
                  : scene.physicalMediaTapeNum
              }
              id={`scene-${index}-tape-num`}
              onChange={(e) =>
                handleSceneFormChange(e, index, "physicalMediaTapeNum")
              }
              disabled={isSaving || scene.physicalMediaType === "Edited"}
            />
          </Form.Group>
        </Row>
      </Form.Group>
    </Form.Group>
  );
};

export default EditSceneForm;
