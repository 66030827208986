import React, { useState, useCallback } from "react";
import { logout } from "../../slices/auth";
import { useDispatch } from "react-redux";
import EditVideoFormState from "../Video/EditVideoFormState";
import UserService from "../../services/user.service";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

/**
 * Defines the submit function for an Edit Video Form
 */
const AddVideo = ({ filterOptions }) => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const emptyVideo = {
    title: "",
    uuid: null,
    duration: "",
    hosted_video_id: "",
    preview_url: "",
    thumbnail_url: "",
    scenes: [
      {
        uuid: null,
        description: "",
        start_date: "",
        end_date: "",
        tags: [],
        locations: [],
        people: [],
        physical_media_type: null,
        physical_tape_number: null,
        start_time_on_video: "00:00:00",
        isNew: true,
      },
    ],
  };

  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    // to log out if the API returns a not-authenticated error
    dispatch(logout());
  }, [dispatch]);

  const handleSubmitVideo = (videoData) => {
    // call the Create Video endpoint
    setIsSaving(true);
    UserService.postVideoCreate(videoData)
      .then(
        (response) => {
          // navigate to the newly created video
          navigate(`/video/${response.data.newVideoId}`);
        },
        (error) => {
          const errorMessage = error.response.data;
          if ("logged_in" in errorMessage && !errorMessage.logged_in) {
            console.log("not logged in error");
            logOut();
          }
          console.log(errorMessage.error);
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Row style={{ paddingTop: 10, paddingBottom: 100 }}>
      <Col md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
        <h1>Add a Video</h1>
        <EditVideoFormState
          video={emptyVideo}
          filterOptions={filterOptions}
          handleCancelEdit={() => console.log("cancel edit")}
          isSaving={isSaving}
          handleSubmitVideo={handleSubmitVideo}
          videoIsNew={true}
        />
      </Col>
    </Row>
  );
};

export default AddVideo;
