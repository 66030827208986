import React from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import VideoDetails from "./VideoDetails";
import VideoCard from "../../components/VideoCard";
import SectionBorder from "../../components/SectionBorder";

const VideoPageContent = ({
  videoData,
  aggData,
  filterOptions,
  signedCdnUrl,
  isEditing,
  setIsEditing,
  videoHeight,
  triggerReload,
  loadingMoreQueue,
  videoQueue,
}) => {

  return (
    <Container fluid className="p-0">
      <Row>
        <Col>
          <div
            style={{
              height: videoHeight,
              width: "100%",
              backgroundColor: "darkgray",
            }}
            className="vid-div"
          >
            {signedCdnUrl ? (
              <iframe
                src={signedCdnUrl}
                allowFullScreen={true}
                position="absolute"
                border="none"
                height="100%"
                width="100%"
                top="0"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                title="video"
              />
            ) : null}
          </div>
        </Col>
      </Row>
        <div>
          <div>
            {/* Video Details */}
            <div className="l-section is--short">
              <div className="l-container is--pt-24">
                  <div>
                  {videoData && aggData ? (
                    <VideoDetails
                      title={
                        videoData.scenes.length > 1
                          ? videoData.title
                          : videoData.scenes[0].description
                      }
                      startDate={aggData.start_date}
                      endDate={aggData.end_date}
                      locations={aggData.locations}
                      tags={aggData.tags}
                      people={aggData.people}
                      scenes={videoData.scenes}
                      video={videoData} /* for EditVideo */
                      filterOptions={filterOptions} /* for EditVideo */
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      triggerReload={triggerReload}
                      comments={videoData.comments}
                    />
                  ) : null}
                  </div>
              </div>
            </div>
            <SectionBorder />
            {/* Video List */}
            <div className="c-video-list">
              <div className="l-section">
                <div className="l-container">
                  <div className="c-video-list_grid">
                    {videoQueue
                      ? videoQueue.map((video, index) => (
                          <div className="indent" key={index}>
                            <VideoCard
                              uuid={video.video_data.uuid}
                              thumbnailUrl={video.video_data.thumbnail_url}
                              title={video.video_data.title}
                              people={video.agg_data.people}
                              locations={video.agg_data.locations}
                              startDate={video.agg_data.start_date}
                              endDate={video.agg_data.end_date}
                              duration={video.video_data.duration}
                              tags={video.agg_data.tags}
                            />
                          </div>
                        ))
                      : null}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {loadingMoreQueue ? <Spinner /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </Container>
  );
};

export default VideoPageContent;
