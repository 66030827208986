import React, { useState, useCallback } from "react";
import { logout } from "../../slices/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditVideoFormState from "./EditVideoFormState";
import UserService from "../../services/user.service";

/**
 * Defines the submit function for an Edit Video Form
 */
const EditVideo = ({
  video,
  filterOptions,
  handleCancelEdit,
  triggerReload,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    // to log out if the API returns a not-authenticated error
    dispatch(logout());
  }, [dispatch]);

  const handleSubmitVideo = (videoData) => {
    // call the Edit endpoint
    setIsSaving(true);
    UserService.postVideoUpdate(videoData, video.uuid)
      .then(
        (response) => {
          console.log(response.data);
          triggerReload();
        },
        (error) => {
          const errorMessage = error.response.data;

          if ("logged_in" in errorMessage && !errorMessage.logged_in) {
            console.log("not logged in error");
            logOut();
          }
          console.log(errorMessage.error);
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleDeleteVideo = () => {
    // call the Delete Video endpoint
    setIsSaving(true);
    UserService.deleteVideo(video.uuid)
      .then(
        (response) => {
          console.log(response.data);
          // navigate home
          navigate("/");
        },
        (error) => {
          const errorMessage = error.response.data;

          if ("logged_in" in errorMessage && !errorMessage.logged_in) {
            console.log("not logged in error");
            logOut();
          }
          console.log(errorMessage.error);
        }
      )
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <EditVideoFormState
      video={video}
      filterOptions={filterOptions}
      handleCancelEdit={handleCancelEdit}
      isSaving={isSaving}
      handleSubmitVideo={handleSubmitVideo}
      handleDeleteVideo={handleDeleteVideo}
      videoIsNew={false}
    />
  );
};

export default EditVideo;
