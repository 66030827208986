import React from "react";
import { Link } from "react-router-dom";
import "./Global.css";

const MemberLink = ({uuid, name, typeClass, image}) =>{
  return (
    <Link
    className={'c-people-link'+ typeClass}
    to={{
      pathname: "/search",
      search: `people=${uuid}`
    }}
    reloadDocument
    >
      <div className={'c-people-link_img-section'+ typeClass}>
        <div className="c-people-link_img-container" style={{backgroundImage: 'url(' + image + ')',}}></div>
        <div className="c-people-link_hover"></div>
      </div>
      <div className="c-people-link_name">
        <h5 className="t-h5">{name}</h5>
      </div>
    </Link>
  );
};

export default MemberLink;