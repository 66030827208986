import React from "react";
import { Link} from "react-router-dom";
import "./links.css";
import "../../components/Global.css";

const CollectionLink = ({uuid, name, type, thumbnailUrl}) => {
  const convertTypeToSearch = (type) => {
    /*
      Convert the 'type' from the API to the appropriate
      search query
    */
    if (type === 'location') {
      return "locations"
    }
    else if (type === 'tag') {
      return "tags"
    }
  };

  return (
  <div className="c-category-card">
    <div className="c-category-card_bg">
      <Link 
          className="c-category-card_img-container" 
          reloadDocument 
          to={{
            pathname: "/search",
            search: `${convertTypeToSearch(type)}=${uuid}`
          }}
        >
        <img className ="c-category-card_img" src={thumbnailUrl} alt="thumbnail of category" />
        <div className="c-category_overlay">
        </div>
        <div className="c-category_title">
            <h4 className="t-h4 is--tc-white">{name}</h4>
        </div>
      </Link>
    </div>
    <div id="bg-green" className="c-card_color-bg is--green"></div>
    <div id="bg-yellow" className="c-card_color-bg is--yellow"></div>
    <div id="bg-red" className="c-card_color-bg is--red"></div>
    <div id="bg-cyan" className="c-card_color-bg is--cyan"></div>
  </div>
  );
};

export default CollectionLink;

