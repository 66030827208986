import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../slices/auth";
import EventBus from "../../common/EventBus";
import { Link } from "react-router-dom";

export const ProfilePage = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          Logged in as <strong>{currentUser.email}</strong>
        </h3>
      </header>
      <Link onClick={logOut}>Logout</Link>
    </div>
  );
};

