import React, { useState, useEffect, useCallback } from "react";
import { logout } from "../../slices/auth";
import { useDispatch } from "react-redux";
import UserService from "../../services/user.service";
import AddVideo from "./AddVideo";

export const AddVideoPage = () => {
  const [filterOptions, setFilterOptions] = useState("");

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    UserService.getSearchFilters().then(
      (response) => {
        setFilterOptions(response.data.filter_options);
      },
      (error) => {
        const errorMessage = error.response.data;

        if ("logged_in" in errorMessage && !errorMessage.logged_in) {
          console.log("not logged in error");
          logOut();
        }
        console.log(errorMessage.error);
      }
    );
  }, [logOut]);

  return (
    <>{filterOptions ? <AddVideo filterOptions={filterOptions} /> : null}</>
  );
};
