import React from "react";
import "../../components/Global.css";

import TagList from "../../components/TagList";
import MemberList from "../../components/MemberList";
import VidButton from "../../components/VidButton";

const FeaturedVideo = ({
  title,
  startDate,
  endDate,
  locations,
  tags,
  people,
  uuid,
  thumbnailUrl,
  daysGap,
}) => {

  const subtitleBasedOnDate = () => {
    if (startDate !== null) {
      const startDateDt = new Date(startDate);
      const year = startDateDt.getFullYear();

      if (daysGap === 0) {
        return `On this day in ${year}`
      }

      if (daysGap <= 7) {
        return `A memory from this week in ${year}`
      }

      return `A memory from ${year}`
    }

    return "Today's featured video"

  };

  const gradientChoices = [
    '255, 0, 0',
    '255, 255, 0',
    '0, 255, 0',
    '0, 255, 255',
    '255, 0, 255',
  ];

  var gradientOne =''
  var gradientTwo =''
  var pickRandomGradientIndex = () => {
    gradientOne = Math.floor(Math.random() * 5);
    gradientTwo = Math.floor(Math.random() * 5);
  }

  pickRandomGradientIndex();
  return (
    <div>
      <div className="c-feat-vid" style={{
          backgroundImage: '-webkit-gradient(linear, left top, right top, from(rgba('+gradientChoices[gradientOne]+', 0.3)), color-stop(20%, rgba('+gradientChoices[gradientOne]+', 0)), color-stop(80%, rgba('+gradientChoices[gradientTwo]+', 0)), to(rgba('+gradientChoices[gradientTwo]+', 0.3))), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.4))), url(' + thumbnailUrl + ')',
          // backgroundImage: 'linear-gradient(90deg, rgba('+gradientChoices[gradientOne]+', 0.3), rgba('+gradientChoices[gradientOne]+', 0) 20%, rgba('+gradientChoices[gradientTwo]+', 0) 80%, rgba('+gradientChoices[gradientTwo]+', 0.3)), linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)),url(' + thumbnailUrl + ')',
          }}>
        <div className="l-section">
          <div className="l-container">
            <div className="c-feat-vid_content-container">
              <div className="c-feat-vid_content-wrapper ">
                <div className="c-feat-vid_badge">
                  <h3 className="t-h3 is--tc-white">{subtitleBasedOnDate()}</h3>
                </div>
                <div className="c-feat-vid_title">
                  <h1 className="t-h1 is--tc-white">{title}</h1>
                </div>
                <TagList 
                  tags={tags}
                  locations={locations} 
                  typeClass=' is--secondary'
                />
                <MemberList 
                  people={people}
                  typeClass=' is--secondary'
                />
                <VidButton 
                  uuid={uuid}
                  text='watch ▶'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedVideo;
