import React from "react";
import { Link } from "react-router-dom";
import "./Global.css";

const MemberLinkLarge = ({uuid, name, image}) =>{
  return (
    <Link
    className='c-lg-people-link'
    to={{
      pathname: "/search",
      search: `people=${uuid}`
    }}
    reloadDocument
    >
      <div className={'c-lg-people-link_img-section'}>
        <div className="c-lg-people-link_img-container" style={{backgroundImage: 'url(' + image + ')',}}></div>
        <div className="c-lg-people-link_color-bg is--green"></div>
        <div className="c-lg-people-link_color-bg is--cyan"></div>
        <div className="c-lg-people-link_color-bg is--red"></div>
        <div className="c-lg-people-link_color-bg is--yellow"></div>
      </div>
      <div className="c-lg-people-link_name">
        <h4 className="t-h4 is--tc-black">{name}</h4>
      </div>
    </Link>
  );
};

export default MemberLinkLarge;