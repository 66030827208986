import React from "react";
import { Link } from "react-router-dom";
import "./Global.css";

const Pill = ({uuid, typeClass, name, type}) =>{
  return (
      <Link
      className={'c-pill'+ typeClass}
      to={{
        pathname: "/search",
        search: `${type}=${uuid}`
      }}
      reloadDocument
      >
        <h5 className="t-h5">{name}</h5>
      </Link>
  );
};

export default Pill;