import axios from "axios";
import authHeader from "./auth-header";
import apiUrl from "./api.js";

const API_URL = apiUrl() + "content/";

const getHomePageContent = () => {
  return axios.get(API_URL + "home/", { headers: authHeader() });
};

const getVideoContent = (id) => {
  return axios.get(API_URL + "video/" + id, { headers: authHeader() });
};

const getVideoQueue = (id, page) => {
  const resultsPerPage = 10;
  const params = new URLSearchParams([
    ["video_id", id],
    ["results_per_page", resultsPerPage],
    ["page", page],
  ]);
  return axios.get(API_URL + "queue", { headers: authHeader(), params });
};

const getSearchResults = (params) => {
  return axios.get(API_URL + "search", { headers: authHeader(), params });
};

const getSearchFilters = () => {
  return axios.get(API_URL + "search-form-setup", { headers: authHeader() });
};

const postVideoUpdate = (data, id) => {
  return axios.post(API_URL + "video/" + id, data, { headers: authHeader() });
};

const postVideoCreate = (data) => {
  return axios.post(API_URL + "video/", data, { headers: authHeader() });
};

const deleteVideo = (id) => {
  return axios.delete(API_URL + "video/" + id, { headers: authHeader() });
};

const putUpdateComment = (data, id) => {
  return axios.put(API_URL + "comment/" + id, data, { headers: authHeader() });
};

const deleteComment = (id) => {
  return axios.delete(API_URL + "comment/" + id, { headers: authHeader() });
}

const createComment = (data) => {
  return axios.post(API_URL + "comment", data, { headers: authHeader() });
}

const UserService = {
  getHomePageContent,
  getVideoContent,
  getVideoQueue,
  getSearchResults,
  getSearchFilters,
  postVideoUpdate,
  postVideoCreate,
  deleteVideo,
  putUpdateComment,
  deleteComment,
  createComment,
};

export default UserService;
