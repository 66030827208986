import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { login } from "../slices/auth";
import { clearMessage } from "../slices/message";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const GoogleAuth = () => {
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  const googleButton = useRef(null);

  useEffect(() => {
    function handleCredentialResponse(response) {
      dispatch(login({ credential: response.credential })).unwrap();
    }

    dispatch(clearMessage());

    const src = "https://accounts.google.com/gsi/client";
    const id =
      "609612830989-3m8ht5svs00kjpvf2so2sbdo2ej73i59.apps.googleusercontent.com";

    loadScript(src)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: id,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(googleButton.current, {
          theme: "outline",
          size: "large",
        });
        google.accounts.id.prompt(); // also display the One Tap dialog
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, [dispatch]);

  return (
    <div>
      <div>{message}</div>
      <div ref={googleButton}></div>
    </div>
  );
};

export default GoogleAuth;
