import React from "react";
import "./Global.css";

import MemberLink from "./MemberLink";

const MemberList = ({people, typeClass}) =>{
  
  const renderMembers = (people) => {
    return people.map((person, index) => (
            <MemberLink key={index}
                uuid={person.uuid}
                name={person.person}
                typeClass = {typeClass}
                image = {person.image}
            />
    ));
  };

  return (
    <div className="c-people-list">
      { people ?
        renderMembers(people)
        : null
      }
    </div>
  );
};

export default MemberList;