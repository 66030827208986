import React from "react";
import VideoCard from "../VideoCard";

const SearchResults = ({ totalResults, videos }) => {
  const renderVideoResults = () => {
    return videos.map((video, index) => (
      <li key={index}>
        <VideoCard
          uuid={video.video_data.uuid}
          thumbnailUrl={video.video_data.thumbnail_url}
          title={video.video_data.title}
          people={video.agg_data.people}
          locations={video.agg_data.locations}
          startDate={video.agg_data.start_date}
          endDate={video.agg_data.end_date}
          duration={video.video_data.duration}
          tags={video.agg_data.tags}
        />
      </li>
    ));
  };

  return (
    <>
      <div>Search results: ({totalResults})</div>
      <ol style={{ listStyle: "none", paddingLeft: 0 }}>
        {renderVideoResults()}
      </ol>
    </>
  );
};

export default SearchResults;
