import React from "react";
import { Link } from "react-router-dom";
import { convertDateRange } from "../../utils/convertDateRange";
import TagList from "../../components/TagList";
import SceneCard from "./SceneCard";
import PhysicalMediaLink from "../../components/ContentLinks/PhysicalMediaLink";
import Row from "react-bootstrap/Row";
import { FiFilm } from "react-icons/fi";
import {
  MdUnfoldMore,
  MdUnfoldLess,
  MdExpandLess,
  MdOutlineEdit,
} from "react-icons/md";
import "./video.css";
import Comments from "./Comments";

import MemberList from "../../components/MemberList";
import dateIcon from "../../images/icon_date.svg";

const VideoDetailsContent = ({
  title,
  toggleScenesOpen,
  handleOpenEdit,
  startDate,
  endDate,
  locations,
  people,
  scenes,
  sceneHeight,
  scenesOpen,
  comments,
  videoUuid,
  tags,
}) => {
  const renderEditButton = () => {
    return (
      <div className="float-right">
        <Link onClick={handleOpenEdit}>
          <MdOutlineEdit />
          Edit
        </Link>
      </div>
    );
  };

  const renderSceneList = (scenes) => {
    return scenes.map((scene, index) => (
      <li key={index}>
        <SceneCard
          description={scene.description}
          locations={scene.locations}
          startDate={scene.start_date}
          endDate={scene.end_date}
          people={scene.people}
          tags={scene.tags}
          physicalMediaType={scene.physical_media_key.media_type}
          physicalMediaNumber={scene.physical_media_key.tape_number}
          index={index}
          startTimeOnVideo={scene.start_time_on_video}
        />
      </li>
    ));
  };

  return (
    <div>
      <div className="c-card">
        <div className="c-card_bg">
          <div className="c-card_content-container">
            <div className="c-card_title-link">
              <h3 className="t-h3">{title}</h3>
            </div>
            <div className="c-card_details">
              <div className="c-card_detail is-last">
                <div className="c-card_icon">
                  <img className ="c-img" src={dateIcon} alt="date icon" loading="lazy"/>
                </div>
                <div className="c-card_detail-info">
                  <h6 className="t-h6 is--tc-black">{convertDateRange(startDate, endDate)}</h6>
                </div>
              </div>
            </div>
            <div className="c-card_lists">
              <TagList 
                locations={locations} 
                tags={tags}
                typeClass=''
              />
              <MemberList 
                people={people}
                typeClass=''
              />
              <div>
                <Comments comments={comments} videoUuid={videoUuid}/>
              </div>
              <div className="c-scenes_container">
                {scenes.length === 1 ? (
                  <div>
                    <FiFilm />{" "}
                    <PhysicalMediaLink
                      mediaType={scenes[0].physical_media_key.media_type}
                      mediaNumber={scenes[0].physical_media_key.tape_number}
                    />
                    {renderEditButton()}
                  </div>
                ) : (
                  <div
                    style={{
                      height: sceneHeight,
                      overflow: "hidden",
                    }}
                  >
                    {renderEditButton()}
                    <div onClick={toggleScenesOpen}>
                      <h2 className="t-h2 is--pointer">
                        Scenes ({scenes.length})
                        {scenesOpen ? <MdUnfoldLess /> : <MdUnfoldMore />}
                      </h2>
                    </div>
                    <ol style={{ listStyle: "none", paddingLeft: 0 }}>
                      {renderSceneList(scenes)}
                    </ol>
                    <div style={{ display:'flex', justifyContent:"center" }}>
                      <Link  onClick={toggleScenesOpen}>
                        <h5 className="t-h5 is--tc-black" >Hide scenes <MdExpandLess /></h5>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Row>

      </Row>
    </div>
  );
};

export default VideoDetailsContent;
