import React from "react";
import "./filterStyles.css";
import { RxCross2 } from "react-icons/rx";

const FilterCheckboxSelected = ({ uuid, name, onClick, filterKey }) => {
  const maxNameLength = 30;

  const truncateName = (name) => {
    if (name.length > maxNameLength) {
      return `${name.substring(0, maxNameLength)}…`;
    }
    return name;
  };

  return (
    <div onClick={() => onClick(uuid, filterKey)} className="filter-selected">
        <RxCross2 /> {truncateName(name)}
    </div>
  );
};

export default FilterCheckboxSelected;
