import React from "react";
import FeaturedVideo from "./FeaturedVideo";
import VideoCard from "../../components/VideoCard";
import FeaturedCollections from "./FeaturedCollections";
import "./home.css";

import Pill from "../../components/Pill";
import VidButton from "../../components/VidButton";
import MemberListLarge from "../../components/MemberListLarge";
import SectionBorder from "../../components/SectionBorder";

const HomePageContent = ({ data }) => {
  const textForSameYearVideo = (code) => {
    if (code === "later that year") {
      return "Later that year";
    } else if (code === "earlier that year") {
      return "Earlier that year";
    } else if (code === "chrono next") {
      return "Next up";
    } else if (code === "chrono previous") {
      return "An earlier video";
    }
  };

  return (
    <div>
      {/* Feature Video Section */}
        {data ? (
          <FeaturedVideo
            title={data.featured_video.video_data.title}
            startDate={data.featured_video.agg_data.start_date}
            endDate={data.featured_video.agg_data.end_date}
            locations={data.featured_video.agg_data.locations}
            tags={data.featured_video.agg_data.tags}
            people={data.featured_video.agg_data.people}
            uuid={data.featured_video.video_data.uuid}
            thumbnailUrl={data.featured_video.video_data.thumbnail_url}
            daysGap={data.featured_video.days_gap}
          />
        ) : null}
      {/* Highlighted Videos Section */}
        <div className="c-highlight-vids">
            <div className="l-section">
              <div className="l-container">
                <div className="c-highlight-vids_grid">
                  {data && data.same_tag ? (
                    <div className="c-highlight-vids_card">
                      <div className="c-highlight-vids_title">
                        <h3 className="t-h3 is--tc-black">                      
                          Another
                          <Pill
                            uuid={data.same_tag.tag.uuid}
                            name={data.same_tag.tag.tag}
                            typeClass = ' is--inline'
                            type = 'tags'
                          />
                          video:</h3>
                      </div>
                      <VideoCard
                          uuid={data.same_tag.video_data.uuid}
                          thumbnailUrl={data.same_tag.video_data.thumbnail_url}
                          title={data.same_tag.video_data.title}
                          people={data.same_tag.agg_data.people}
                          locations={data.same_tag.agg_data.locations}
                          startDate={data.same_tag.agg_data.start_date}
                          endDate={data.same_tag.agg_data.end_date}
                          duration={data.same_tag.video_data.duration}
                          tags={data.same_tag.agg_data.tags}
                        />
                    </div>
                  ) : null}
                  {data && data.same_year ? (
                    <div className="c-highlight-vids_card">
                      <div className="c-highlight-vids_title">
                        <h3 className="t-h3 is--tc-black">{textForSameYearVideo(data.same_year.reason_code)}:</h3>
                      </div>
                      <VideoCard
                        uuid={data.same_year.video_data.uuid}
                        thumbnailUrl={data.same_year.video_data.thumbnail_url}
                        title={data.same_year.video_data.title}
                        people={data.same_year.agg_data.people}
                        locations={data.same_year.agg_data.locations}
                        startDate={data.same_year.agg_data.start_date}
                        endDate={data.same_year.agg_data.end_date}
                        duration={data.same_year.video_data.duration}
                        tags={data.same_year.agg_data.tags}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
        </div>
      {/* Feeling Lucky Section */}
        <div className="c-lucky">
          <div className="l-section">
            <div className="l-container">
              <div className="c-lucky_btn-container">
                <div className="c-lucky_text">
                  <h3 className="t-h3 is--tc-white">Not sure what to watch?</h3>
                  <h5 className="t-h5 is--tc-white">(please don't be a voice recital)</h5>
                </div>
                {data && data.random_video ? (
                  <VidButton 
                      uuid={data.random_video.video_data.uuid}
                      text="I'm feeling lucky"
                  />
                ) : null}
              </div>  
            </div>
          </div>
        </div>
      {/* Categories Section */}
        <div className="c-categories">
          <div className="l-section">
            <div className="l-container">
              {data && data.featured_collections ? (
                <FeaturedCollections data={data.featured_collections} />
              ) : null}
            </div>
          </div>
        </div>
      {/* Section Border */}
        <SectionBorder />
      {/* Members Section */}
      <div className="c-members">
        <div className="l-section">
          <div className="l-container">
            <div className="c-section_wrapper">
              <div className="c-section_title">
              <h3 className="t-h3 is--tc-black">Browse videos by person</h3>
              </div>
              {data ? <MemberListLarge
                people={data.people}
              /> : null}
            </div>
          </div>
        </div>        
      </div>
    </div>
  );
};

export default HomePageContent;