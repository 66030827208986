import React from "react";
import { Link } from "react-router-dom";
import "./Global.css";

const VidButton = ({uuid, text}) =>{
  return (
    <div style={{display:'flex'}}>
      <Link
        className="c-btn"
        to={`/video/${uuid}`}
        reloadDocument
      >
        <div className="c-btn_content">
          <p className="t-btn">{text}</p>
        </div>
      </Link>
    </div>
  );
};

export default VidButton;