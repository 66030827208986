import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { HomePage } from "./pages/Home";
import { SearchPage } from "./pages/Search";
import { VideoPage } from "./pages/Video";
import { ProfilePage } from "./pages/Profile";
import { AddVideoPage } from "./pages/AddVideo";

import GoogleAuth from "./components/GoogleAuth";
import NavigationBar from "./components/NavigationBar";


import { logout } from "./slices/auth";
import EventBus from "./common/EventBus";

const App = () => {

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <Router>
      <div>
        <NavigationBar/>
        <div>
          <Routes>
            <Route path="/" element={currentUser ? <HomePage /> : <GoogleAuth />}/>
            <Route path="/profile" element={currentUser ? <ProfilePage /> : <GoogleAuth />} />
            <Route path="/search" element={currentUser ? <SearchPage /> : <GoogleAuth />} />
            <Route path="/video/:id" element={currentUser ? <VideoPage /> : <GoogleAuth />} />
            <Route path="/new-video" element={currentUser ? <AddVideoPage /> : <GoogleAuth />} />
            <Route path="*" element={<Navigate to="/" />}/>
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;