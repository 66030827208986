import React, { useState, useEffect } from "react";
import EditVideoForm from "./EditVideoForm";

/**
 * Manages the state of EditVideoForm
 */
const EditVideoFormState = ({
  video,
  filterOptions,
  handleCancelEdit,
  isSaving,
  handleSubmitVideo,
  handleDeleteVideo,
  videoIsNew,
}) => {
  const [videoState, setVideoState] = useState({});
  const [sceneState, setSceneState] = useState({});
  const [validated, setValidated] = useState(false);
  const [adminUnlocked, setAdminUnlocked] = useState(videoIsNew);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleVideoFormChange = (e, field) => {
    setVideoState({ ...videoState, [field]: e.target.value });
  };

  const handleSceneFormChange = (e, sceneIndex, field) => {
    setSceneState(
      sceneState.map((item, idx) => {
        if (sceneIndex === idx) {
          return { ...item, [field]: e.target.value };
        } else {
          return item;
        }
      })
    );
  };

  const handleUpdatePhysMediaType = (e, sceneIndex) => {
    let newSceneState = sceneState.map((item, idx) => {
      if (sceneIndex === idx && e.target.value === "Edited") {
        /* if the new media type is 'Edited', then also set the 
        tape number to null */
        return {
          ...item,
          physicalMediaType: e.target.value,
          physicalMediaTapeNum: "",
        };
      } else if (sceneIndex === idx) {
        return { ...item, physicalMediaType: e.target.value };
      } else {
        return item;
      }
    });

    setSceneState(newSceneState);
  };

  const handleAdminToggleLock = (e) => {
    e.preventDefault();
    setAdminUnlocked(!adminUnlocked);
  };

  const handleNewFilterUpdate = (sceneIndex, filterKey, value) => {
    setSceneState(
      sceneState.map((item, idx) => {
        if (sceneIndex === idx) {
          return {
            ...item,
            newFilterBeingEdited: {
              ...item.newFilterBeingEdited,
              [filterKey]: value,
            },
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleToggleAddingNewFilter = (sceneIndex, filterKey) => {
    const curState = sceneState[sceneIndex].enteringNewFilter[filterKey];
    setSceneState(
      sceneState.map((item, idx) => {
        if (sceneIndex === idx) {
          const newEnteringNewFilter = {
            ...item.enteringNewFilter,
            [filterKey]: !curState,
          };
          return {
            ...item,
            enteringNewFilter: newEnteringNewFilter,
            newFilterBeingEdited: {
              tags: "",
              locations: "",
              people: "",
            },
          };
        } else {
          return item;
        }
      })
    );
  };

  const handleCheckboxChange = (index, filterKey, sceneIndex) => {
    setSceneState(
      sceneState.map((scene, idx) => {
        if (idx === sceneIndex) {
          const updatedFilter = scene[filterKey].map((item, itemIndex) => {
            if (itemIndex === index) {
              return { ...item, isChecked: !item.isChecked };
            } else {
              return item;
            }
          });
          return { ...scene, [filterKey]: updatedFilter };
        } else {
          return scene;
        }
      })
    );
  };

  const getCheckedUuids = (filters) => {
    return filters
      .map((item) => (item.isChecked ? item.uuid : null))
      .filter((itemId) => itemId !== null);
  };

  const handleDeleteScene = (e, sceneIndex) => {
    e.preventDefault();
    console.log(`Delete scene at index ${sceneIndex}`);

    // remove the scene from the form
    setSceneState(
      sceneState.filter((item, index) => {
        return index !== sceneIndex;
      })
    );
  };

  const handleToggleDeleteModal = (e) => {
    setShowDeleteModal(!showDeleteModal);

  };

  const handleRemoveNewFilter = (e, filterKey, sceneIndex, filterIndex) => {
    e.preventDefault();

    const newCommittedFilter = sceneState[sceneIndex].committedNewFilter[
      filterKey
    ].filter((item, index) => {
      return index !== filterIndex;
    });

    const newSceneState = sceneState.map((scene, index) => {
      if (index === sceneIndex) {
        return {
          ...scene,
          committedNewFilter: {
            ...scene.committedNewFilter,
            [filterKey]: newCommittedFilter,
          },
        };
      } else {
        return scene;
      }
    });

    setSceneState(newSceneState);
  };

  const unpackScene = (scene, isNew) => {
    // unpack scene data into the state

    const checkedFilters = {
      tags: scene.tags
        ? scene.tags.map((item) => {
            return item.uuid;
          })
        : [],
      locations: scene.locations
        ? scene.locations.map((item) => {
            return item.uuid;
          })
        : [],
      people: scene.people
        ? scene.people.map((item) => {
            return item.uuid;
          })
        : [],
    };


    const data = {
      id: scene.uuid,
      description: scene.description,
      startDate: scene.start_date,
      endDate: scene.end_date,
      isNew: isNew,
      physicalMediaType: scene.physical_media_type,
      physicalMediaTapeNum: scene.physical_tape_number,
      tags: filterOptions.tags.map((item) => {
        return { ...item, isChecked: checkedFilters.tags.includes(item.uuid) };
      }),
      locations: filterOptions.locations.map((item) => {
        return {
          ...item,
          isChecked: checkedFilters.locations.includes(item.uuid),
        };
      }),
      people: filterOptions.people.map((item) => {
        return {
          ...item,
          isChecked: checkedFilters.people.includes(item.uuid),
        };
      }),
      enteringNewFilter: {
        tags: false,
        people: false,
        locations: false,
      },
      newFilterBeingEdited: {
        tags: "",
        people: "",
        locations: "",
      },
      committedNewFilter: {
        tags: [],
        people: [],
        locations: [],
      },
      startTimeOnVideo: scene.start_time_on_video,
    };
    return data;
  };

  useEffect(() => {
    // unpack the props into the starting values for the state
    setVideoState({
      title: video.title,
      duration: video.duration,
      hostedVideoId: video.hosted_video_id,
      thumbnailUrl: video.thumbnail_url,
      previewUrl: video.preview_url,
    });
    setSceneState(
      video.scenes.map((item) => {
        // if we're editing an existing scene, there is no isNew field
        const isNew = 'isNew' in item ? item.isNew : false;
        return unpackScene(item, isNew);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    video.scenes,
    video.title,
    filterOptions,
    video.duration,
    video.hosted_video_id,
    video.thumbnail_url,
    video.preview_url,
  ]);

  const handleAddScene = (e, prevSceneIdx) => {
    e.preventDefault();

    const emptySceneData = {
      uuid: null,
      description: "",
      start_date: "",
      end_date: "",
      tags: [],
      locations: [],
      people: [],
      physical_media_type: null,
      physical_tape_number: null,
      start_time_on_video: "",
    };

    const isNew = true;
    let scene = unpackScene(emptySceneData, isNew);

    // insert into the state
    const newSceneIdx = prevSceneIdx + 1;
    const nextSceneState = [
      ...sceneState.slice(0, newSceneIdx),
      scene,
      ...sceneState.slice(newSceneIdx),
    ];

    setSceneState(nextSceneState);
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      console.log("Form validation failed - preventing submit.");
      return;
    }

    // Option 1: "submit" is just the user entering a new tag/location/person option
    const onlyCommitNewFilters = sceneState.some((item) => {
      return (
        item.enteringNewFilter["tags"] ||
        item.enteringNewFilter["locations"] ||
        item.enteringNewFilter["people"]
      );
    });
    console.log("submitting edit video form");
    console.log(`only committing new filters? ${onlyCommitNewFilters}`);

    if (onlyCommitNewFilters) {
      setSceneState(
        sceneState.map((scene) => {
          let newScene = { ...scene };

          if (
            scene.enteringNewFilter["tags"] &&
            scene.newFilterBeingEdited["tags"].length > 0
          ) {
            newScene.committedNewFilter["tags"] = [
              ...scene.committedNewFilter["tags"],
              scene.newFilterBeingEdited["tags"],
            ];
            newScene.enteringNewFilter["tags"] = false;
            newScene.newFilterBeingEdited["tags"] = "";
          }

          if (
            scene.enteringNewFilter["locations"] &&
            scene.newFilterBeingEdited["locations"].length > 0
          ) {
            newScene.committedNewFilter["locations"] = [
              ...scene.committedNewFilter["locations"],
              scene.newFilterBeingEdited["locations"],
            ];
            newScene.enteringNewFilter["locations"] = false;
            newScene.newFilterBeingEdited["locations"] = "";
          }

          if (
            scene.enteringNewFilter["people"] &&
            scene.newFilterBeingEdited["people"].length > 0
          ) {
            newScene.committedNewFilter["people"] = [
              ...scene.committedNewFilter["people"],
              scene.newFilterBeingEdited["people"],
            ];
            newScene.enteringNewFilter["people"] = false;
            newScene.newFilterBeingEdited["people"] = "";
          }

          return newScene;
        })
      );
      return;
    }

    // Option 2: "submit" is the user submitting the edited scene/video metadata
    // to be registered by the backend
    const requestSceneData = sceneState.map((scene) => {
      // prepare each scene object in the format expected by the API
      return {
        id: scene.id,
        tags: getCheckedUuids(scene.tags),
        people: getCheckedUuids(scene.people),
        locations: getCheckedUuids(scene.locations),
        description: scene.description,
        tagsToCreate: scene.committedNewFilter["tags"],
        locationsToCreate: scene.committedNewFilter["locations"],
        peopleToCreate: scene.committedNewFilter["people"],
        isNew: scene.isNew,
        startDate: scene.startDate,
        endDate: scene.endDate,
        physicalMediaType: scene.physicalMediaType,
        physicalMediaTapeNum: scene.physicalMediaTapeNum,
        startTimeOnVideo: scene.startTimeOnVideo,
      };
    });

    let requestData = {
      scenes: requestSceneData,
      video: {
        id: video.uuid, // from video prop, since this doesn't change
        hostedVideoId: videoState.hostedVideoId,
        thumbnailUrl: videoState.thumbnailUrl,
        previewUrl: videoState.previewUrl,
        duration: videoState.duration,
        title: sceneState.length > 1 ? videoState.title : sceneState[0].description,
      },
    };

    // send the request data up for the API call
    handleSubmitVideo(requestData);
  };

  return (
    <EditVideoForm
      handleSubmit={handleSubmit}
      video={videoState}
      scenes={sceneState}
      handleVideoFormChange={handleVideoFormChange}
      handleCheckboxChange={handleCheckboxChange}
      handleCancelEdit={handleCancelEdit}
      isSaving={isSaving}
      handleToggleAddingNewFilter={handleToggleAddingNewFilter}
      handleNewFilterUpdate={handleNewFilterUpdate}
      handleRemoveNewFilter={handleRemoveNewFilter}
      handleAddScene={handleAddScene}
      handleDeleteScene={handleDeleteScene}
      handleUpdatePhysMediaType={handleUpdatePhysMediaType}
      validated={validated}
      adminUnlocked={adminUnlocked}
      handleAdminToggleLock={handleAdminToggleLock}
      handleSceneFormChange={handleSceneFormChange}
      videoIsNew={videoIsNew}
      handleDeleteVideo={handleDeleteVideo}
      handleToggleDeleteModal={handleToggleDeleteModal}
      showDeleteModal={showDeleteModal}
    />
  );
};

export default EditVideoFormState;
